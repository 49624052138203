import React from 'react';
import { Link } from 'gatsby'
import Author from '@elements/Author/Author'
// import MailingList from '@elements/MailingList/MailingList'
import Container from '@layout/Container/Container'
import Col from '@layout/Col/Col'

const Footer = () => {
  return (
    <div>
      <footer>
        { /* <MailingList /> */ }
        {/* <Author /> */}
        <Container>
          <Col display="flex" flex={1} justifyContent="space-between">
            <p>
              © {new Date().getFullYear()} Really Trulys LLC
            </p>
            <Link to="/sitemap.xml">
              Sitemap
            </Link>
          </Col>
        </Container>
      </footer>
    </div>
   );
}

export default Footer;